import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useLazyQuery } from '@apollo/client';

import { GET_GLOSSARY_TERMS_FROM_TEXT } from '~/graphql/glossary';
import useDebounce from '~/hooks/useDebounce';
import useIsSSR from '~/hooks/useIsSSR';
import useWaitForAllQueriesToBeResolved from '~/hooks/useWaitForAllQueriesToBeResolved';
import { GlossaryTerm, Maybe, Query, QueryGetGlossaryTermsFromTextArgs } from '~/types';
import { throwOnlyInDev } from '~/util/throwOnlyInDev';
import { toBoolean } from '~/utils/toBoolean';

type TermsData = {
  text: string;
  terms: GlossaryTerm[];
};
type GlossaryTermsMap = Record<string, TermsData>;

interface GlossaryTermsProviderProps {
  children: ReactNode;
}

const GlossaryTermsCtx = createContext<{
  addTextEntry: (key: string, val: string) => void;
  removeTextEntry: (key: string) => void;
  refetch: () => void;
  glossaryTermsMap: GlossaryTermsMap;
}>({
  addTextEntry: () => {
    throwOnlyInDev(new Error('addTextEntry called before initialisation'));
  },
  removeTextEntry: () => {
    throwOnlyInDev(new Error('removeTextEntry called before initialisation'));
  },
  refetch: () => {
    throwOnlyInDev(new Error('refetch called before initialisation'));
  },
  glossaryTermsMap: {},
});

export const useTermsContext = () => {
  const context = useContext(GlossaryTermsCtx);

  if (!context) {
    throw new Error('useTermsContext must be used within a GlossaryTermsProvider');
  }

  return context;
};

const FETCH_DELAY = 300;

const GlossaryTermsProvider: FC<GlossaryTermsProviderProps> = ({ children }) => {
  const textsByComponentIdMapRef = useRef<Record<string, string>>({});
  const [glossaryTermsMap, setGlossaryTermsMap] = useState<GlossaryTermsMap>({});
  const [refetchingAllowed, setRefetchingAllowed] = useState(false);
  const isSSR = useIsSSR();

  const [getTerms, { called, loading }] = useLazyQuery<
    Pick<Query, 'getGlossaryTermsFromText'>,
    QueryGetGlossaryTermsFromTextArgs
  >(GET_GLOSSARY_TERMS_FROM_TEXT, {
    onCompleted: (data) => {
      const textByComponentIdEntries = Object.entries<string>(textsByComponentIdMapRef.current);

      const termEntries = data?.getGlossaryTermsFromText?.items
        ?.filter(toBoolean<Maybe<GlossaryTerm>[]>)
        .map((termsForTextEntry, index) => {
          const [componentId, componentText] = textByComponentIdEntries[index];
          const sanitizedTerms = termsForTextEntry.filter(toBoolean<GlossaryTerm>);

          if (!componentId || !sanitizedTerms.length) {
            return null;
          }

          return [
            componentId,
            {
              text: componentText,
              terms: sanitizedTerms,
            },
          ];
        })
        .filter(toBoolean<[string, TermsData]>);

      if (!termEntries) {
        return;
      }

      setGlossaryTermsMap(Object.fromEntries<TermsData>(termEntries));
    },
  });

  const fetchTerms = useCallback(() => {
    const texts = Object.values<string>(textsByComponentIdMapRef.current);

    if (!texts.length) {
      return;
    }

    getTerms({
      variables: {
        page: window.location.pathname,
        texts,
      },
    });
  }, [textsByComponentIdMapRef, getTerms]);

  const canRefetch = !isSSR && called && !loading && refetchingAllowed;

  useEffect(() => {
    if (!canRefetch) {
      return;
    }

    setRefetchingAllowed(false);
    fetchTerms();
  }, [canRefetch, fetchTerms]);

  useWaitForAllQueriesToBeResolved(fetchTerms, FETCH_DELAY);
  const triggerRefetch = useDebounce(() => setRefetchingAllowed(true), FETCH_DELAY);

  const addTextEntry = useCallback(
    (key: string, text: string) => {
      textsByComponentIdMapRef.current[key] = text;
    },
    [textsByComponentIdMapRef],
  );

  const removeTextEntry = useCallback(
    (key: string) => {
      delete textsByComponentIdMapRef.current[key];
    },
    [textsByComponentIdMapRef],
  );

  return (
    <GlossaryTermsCtx.Provider
      value={{
        addTextEntry,
        removeTextEntry,
        glossaryTermsMap,
        refetch: triggerRefetch,
      }}
    >
      {children}
    </GlossaryTermsCtx.Provider>
  );
};

export default GlossaryTermsProvider;
