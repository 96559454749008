import { ApolloClient, createHttpLink, gql } from '@apollo/client';
import { InMemoryCache, PossibleTypesMap } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import fetch from 'cross-fetch';

import possibleTypes from '~/graphql.schema.json';
import TrackQueryLink from '~/graphql/links/TrackQueryLink';
import Cookies from '~/utils/cookies';

declare global {
  interface Window {
    __APOLLO_STATE__: any;
    videoEvents: any;
    verseEvents: any;
  }
}

window.__APOLLO_STATE__ = window.__APOLLO_STATE__ || {};

const merge = (existing, incoming) => {
  if (incoming?.paginatorInfo?.currentPage <= existing?.paginatorInfo?.currentPage) {
    return incoming;
  }

  const existingData = existing?.data || [];
  const incomingData = incoming?.data || [];
  const mergedData = [...existingData, ...incomingData];

  return { ...incoming, data: mergedData };
};

/**
 * Extracts `PossibleTypesMap` as accepted by `@apollo/client` from GraphQL introspection query result
 */
const introspectionToPossibleTypes = (introspectionQueryResultData): PossibleTypesMap => {
  const possibleTypes = {};

  introspectionQueryResultData.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
    }
  });

  return possibleTypes;
};

type CreateApolloClient = {
  handleError: (error: any, operation: any) => void;
  getToken: (() => Promise<string | undefined>) | null;
  serverCookies?: typeof Cookies;
  host?: string;
  lang: string;
};

const createApolloClient = ({
  handleError,
  serverCookies = undefined,
  host = '',
  getToken,
  lang,
}: CreateApolloClient) => {
  const ssrMode = !!serverCookies;
  let cookies;
  if (ssrMode) {
    cookies = {
      get: (name) => serverCookies && serverCookies[name],
    };
  } else {
    cookies = Cookies;
  }

  const windowHostname = window?.location?.hostname;
  const headers = {
    Language: lang,
    'client-referer': windowHostname || host,
  };
  const apiOrigin = process.env.REACT_APP_API_URL;
  const httpLinkOptions = {
    credentials: 'same-origin',
    headers,
  } as const;

  const httpLink = createHttpLink({
    uri: apiOrigin,
    ...httpLinkOptions,
    fetch,
  });

  const restLink = new RestLink({
    uri: apiOrigin?.replace('/graphql', ''),
    ...httpLinkOptions,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token;
    if (getToken) {
      try {
        token = await getToken();
      } catch (e) {}
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
        'Access-Control-Allow-Origin': '*',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      let isUNAUTHORIZED = false;
      graphQLErrors.forEach(({ message }) => {
        if (message.includes('UNAUTHORIZED')) {
          isUNAUTHORIZED = true;
        }
      });
      if (isUNAUTHORIZED) {
        if (ssrMode) {
          cookies.clear();
          return handleError(graphQLErrors, operation);
        }
        try {
          const oldHeaders = operation.getContext().headers;
          if (oldHeaders && oldHeaders.retried) {
            return handleError(graphQLErrors, operation);
          }
          operation.setContext({
            headers: {
              ...oldHeaders,
              // authorization: handleRefreshToken(),
              retried: 'true',
            },
          });
          return forward(operation);
        } catch (err) {
          cookies.clear();
          localStorage.clear();
          return handleError(graphQLErrors, operation);
        }
      } else {
        return handleError(graphQLErrors, operation);
      }
    }
    if (networkError) {
      return;
    }
  });

  const cache = ssrMode
    ? new InMemoryCache({ possibleTypes: introspectionToPossibleTypes(possibleTypes) })
    : new InMemoryCache({
        possibleTypes: introspectionToPossibleTypes(possibleTypes),
        typePolicies: {
          Query: {
            fields: {
              getGeneralListAssignmentsManager: {
                keyArgs: false,
                merge,
              },
              getLearnerAssignmentsListPaginated: {
                keyArgs: false,
                merge,
              },
              getAnnouncementsList: {
                keyArgs: false,
                merge,
              },
            },
          },
          Answer: {
            keyFields: ['id', 'title'],
          },
        },
      }).restore(window.__APOLLO_STATE__);

  cache.writeQuery({
    query: gql`
      query GetHasNewsInit {
        hasNews
      }
    `,
    data: {
      hasNews: true,
    },
  });

  const trackQueryLink = new TrackQueryLink();

  return new ApolloClient({
    ssrMode,
    link: errorLink.concat(authLink.concat(restLink.concat(trackQueryLink.concat(httpLink)))),
    ssrForceFetchDelay: 1000,
    cache,
    resolvers: {},
  });
};

export default createApolloClient;
