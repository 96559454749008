import { useCallback, useRef } from 'react';

const useDebounce = (callback: Function, timeout: number) => {
  const timeoutIdRef = useRef<number>();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const trigger = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(callbackRef.current, timeout);
  }, [timeoutIdRef, callbackRef, timeout]);

  return trigger;
};

export default useDebounce;
