import { gql } from '@apollo/client';

export const GET_GLOSSARY_TERMS_FROM_TEXT = gql`
  query GetGlossaryTermsFromText($page: String, $texts: [String!]) {
    getGlossaryTermsFromText(page: $page, texts: $texts) {
      items {
        id
        title
        definition
        slug
        position
      }
    }
  }
`;
