import { useRef } from 'react';

import { QueriesStatusesMap, QueryEvents, QueryStatus } from '~/graphql/links/TrackQueryLink';
import useIsSSR from '~/hooks/useIsSSR';

import useEventListener from './useEventListener';

const useWaitForAllQueriesToBeResolved = (callback: Function, delay = 0) => {
  const interval = useRef<ReturnType<typeof setInterval>>();
  const isSSR = useIsSSR();

  useEventListener(QueryEvents.QueryResolved, (e: CustomEvent<QueriesStatusesMap>) => {
    if (isSSR || !callback) {
      return;
    }

    const queriesStatuses = e.detail;

    if (interval.current) {
      clearTimeout(interval.current);
    }

    interval.current = setInterval(async () => {
      const pendingQueries = Object.values(queriesStatuses).filter(
        (status) => status === QueryStatus.Pending,
      );

      if (pendingQueries.length === 0) {
        callback();
        clearInterval(interval.current);
      }
    }, delay);
  });
};

export default useWaitForAllQueriesToBeResolved;
