import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client';

export enum QueryStatus {
  Pending,
  Resolved,
}

export enum QueryEvents {
  QueryResolved = 'queryResolved',
}

export type QueriesStatusesMap = Record<string, QueryStatus>;

class TrackQueryLink extends ApolloLink {
  private readonly queriesStatusesMap: QueriesStatusesMap;

  constructor() {
    super();
    this.queriesStatusesMap = {};
  }

  request(operation: Operation, forward?: NextLink): Observable<FetchResult> {
    this.registerPendingQuery(operation.operationName);

    return new Observable((observer) => {
      const observable = forward?.(operation);
      const subscription = observable?.subscribe({
        next: (value) => observer.next(value),
        error: (networkError) => {
          this.runEffects(operation.operationName);
          observer.error(networkError);
        },
        complete: () => {
          this.runEffects(operation.operationName);
          observer.complete();
        },
      });

      return () => {
        this.runEffects(operation.operationName);
        subscription?.unsubscribe();
      };
    });
  }

  private registerPendingQuery(operationName: string) {
    this.queriesStatusesMap[operationName] = QueryStatus.Pending;
  }

  private runEffects(operationName: string) {
    this.queriesStatusesMap[operationName] = QueryStatus.Resolved;

    window?.dispatchEvent?.(
      new CustomEvent(QueryEvents.QueryResolved, {
        detail: this.queriesStatusesMap,
      }),
    );
  }
}

export default TrackQueryLink;
